import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { confirmable } from 'react-confirm'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { formatMessage, FormattedMessage } from '../../../translations'
import { Dialog } from '../../../shared/components/Dialog'
import { store as imageStore } from '../..'
import { i18n } from '../../../shared/utils'
import cacheImage from '../../cacheImage'
import ImagePropagationLanguageEntry from './ImagePropagationLanguageEntry'

const css = /* typeof window === 'undefined' ? {} : */require('../../styles.scss')

/**
  * Renders two versions of the dialog.  One if the image is switched,
  * then it uses a cached image to show before and after.
  * The second is if there was no image and then it is being added.  Since the
  * image was deleted or never there, we don't have the connection to the other
  * images and need to show only a basic dialog
  */
@confirmable
@observer
class ImagePropagation extends Component {

  static propTypes = {
    show: PropTypes.bool,            // indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.shape({
      langList: PropTypes.object,
      currentLang: PropTypes.string,
      cachedImage: PropTypes.object,
      channel: PropTypes.string,
      hideLangVariantList: PropTypes.array,
    }),
    handler: PropTypes.object,        // Set of handler functions
  }

  constructor(props) {
    super(props)

    this.completeLanguageSet = this.props.options.langList
      .map(lang => lang.createdIso)
      .filter(entry => entry !== props.options.currentLang)
      .filter(entry => !props.options.hideLangVariantList.find(el => el === entry))

    // specificed cache id per load of IP, so that images are not forced loaded for every re-render
    this.instanceCacheBust = new Date().getTime()
  }

  componentWillMount() {
    // update the current image in the store for this dialog
    this.currentImage = imageStore.getById(this.props.options.currImage.id)
    if (!this.currentImage) {
      imageStore.load(this.props.options.currImage.id)
        .then((model) => {
          this.currentImage = model
        })
    }
  }

  /** eslint-disable react/sort-comp */
  /** Actions on the component state */
  @action setLangGroupSelect(group) {
    this.langSetSelected = group
  }

  /* local state by observables */
  @observable langSetSelected = []

  @observable currentImage
  /** eslint-enable react/sort-comp */


  getCurrentImageForLanguage(lang = this.props.options.currentLang) {
    // this will either create a new image if the src can be established or just
    // be null
    return cacheImage({
      src: this.currentImage && i18n(
        this.currentImage,
        `draft.channels.${this.props.options.channel}.url`,
        lang
      )
    }, this.instanceCacheBust)

  }

  @autobind
  handleCommit(e) {
    e.preventDefault()
    e.stopPropagation()

    // If nothing selected, close the dialog
    if (!this.langSetSelected.length) {
      return this.props.dismiss()
    }

    // data type required by image update call, could be moved closer to the call
    const languageReferences = this.langSetSelected

    // Get the original image to pass its url
    const { options: { currentLang } } = this.props
    const originalImage = i18n(this.currentImage, 'draft.original', currentLang)

    return (
      this.props.proceed({
        languageReferences,
        url: originalImage.url
      })
    )
  }

  @autobind
  handleCheckboxChange({ target: { checked, name } }) {
    let currentList = this.langSetSelected

    // Select All checkbox, either all on or off
    if (name === 'all') {
      currentList = checked ? this.completeLanguageSet : []
    }

    // single selections
    else {
      if (checked) {
        if (currentList.indexOf(name) === -1) {
          currentList.push(name)
        }
      }
      else {
        currentList.splice(currentList.indexOf(name), 1)
      }
    }

    this.setLangGroupSelect(currentList)

  }

  renderList() {

    const { cachedImage, currentLang } = this.props.options

    return (this.completeLanguageSet.map((languageName) => {

      const isCurrent = languageName === currentLang

      const realName = cachedImage
        ? (this.currentImage
        && i18n(this.currentImage, 'draft.original.realName', languageName))
        : ''

      return (<ImagePropagationLanguageEntry
        key={languageName}
        languageName={languageName}
        disabled={isCurrent}
        checked={this.langSetSelected.indexOf(languageName) !== -1 || isCurrent}
        realName={realName}
        cachedImage={cachedImage}
        currentImage={this.getCurrentImageForLanguage(languageName)}
        onCheck={this.handleCheckboxChange}
      />)

    }))
  }

  renderImagePreview(type, image) {
    return (<div
      className={classNames(
        'grid-block shrink',
        css.imagePropagationPreviewImage
      )}
    >
      <img
        src={image && image.src}
        role="presentation"
      />
      <div className={classNames(css.imageOverlay, 'align-center')}>
        <FormattedMessage id={`image.image-propagation-${type}`} />
      </div>
    </div>)
  }

  render() {
    const { options: { cachedImage, currentLang } } = this.props
    const newImage = this.getCurrentImageForLanguage()

    return (
      <Dialog
        className={classNames(
          css.imagePropagation,
          'modal-sectionized'
        )}
        overlayClassName={css.imagePropagation}
        isOpen={this.props.show}
        title={formatMessage({ id: 'image.image-propagation-title' })}
        onRequestClose={this.handleRequestClose}
        id="imagePropagation"
        onCancel={this.props.dismiss}
        onCommit={this.handleCommit}
      >
        <div className="align-center grid-block vertical">

          <div
            className={classNames(
              css.imagePropagationDescriptionContainer,
              'grid-block',
              'horizontal',
              'modal-section'
            )}
          >

            <div className="grid-block vertical v-align align-center">

              <FormattedMessage id="image.image-propagation-action" />

              <br />

              <div>
                <FormattedMessage id="image.image-propagation-language" />:
              &nbsp;
                <span className={classNames(css.imagePropagationCurrentLang)}>
                  {currentLang}
                </span>
              </div>
            </div>

            {cachedImage ? this.renderImagePreview('old', cachedImage) : null}

            {this.renderImagePreview('new', newImage)}

          </div>


          <hr className="modal-section-divider" />


          <div className="grid-block modal-section vertical">

            <div className={css.imagePropagationDescription}>
              <FormattedMessage id="image.image-propagation-options" />
            </div>


            <div className="grid-block vertical">

              <div className="grid-block overflow v-align headlines">

                <div className="grid-block medium-4">
                  <input
                    id="all"
                    name="all"
                    onChange={this.handleCheckboxChange}
                    type="checkbox"
                  />
                  <label htmlFor="all">
                    <FormattedMessage id="image.image-propagation-language" />
                  </label>
                </div>

                <div
                  className={classNames(
                    'grid-block medium-5'
                  )}
                >
                  <FormattedMessage id="image.image-propagation-image-title" />
                </div>

                <div
                  className={classNames(
                    'grid-block medium-2 small',
                    css.imagePropagationPreviewImage
                  )}
                >
                  <FormattedMessage id="image.image-propagation-image-preview" />
                </div>

              </div>


              <div className={classNames(css.imagePropagationLanguageList)}>
                {this.renderList()}
              </div>

            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export { ImagePropagation as default }
