import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import { FormattedMessage, formatMessage } from '../../translations'

import { store as pageStore } from '..'
import { censhareStore } from '../../censhare/reducer'

import { testClass } from '../../shared/utils'
import { filter, deepGet } from '../../shared/obj'
import { getI18nLangFromObject } from '../../shared/utils/i18n'
import { i18n } from '../../shared/utils'
import { filterDivAttrs } from '../../shared/react-helpers'
import Dropdown, {
  DropdownContainer, Entry, DropdownOpener, Separator
} from '../../shared/components/Dropdown'
import alert from '../../shared/components/Alert'
import EditItemCommand from '../../shared/commands/EditItemCommand.js'
import commandConnector from '../../shared/lib/command'
import { dispatcher } from '../../shared/lib/command'
import {
  PAGE_TYPE_ARCHIVE_PAGE,
  PAGE_TYPE_ARCHIVE_SPECIAL_PAGE
} from '../../shared/const'
import { hasPermissions } from '../../shared/utils/user-rights'
import { store as uiStore } from '../../ui'

import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'
import { confirmDelete, pageSettings, publishPage, pagePlanning, pageSecurity, internalLink } from '../containers/dialogs'
import { censhareRefresh } from '../../censhare/containers/dialogs'

const ObservedEntry = observer((props) => {

  const { entry, id, onClick } = props

  return (<Entry
    {...entry.state}
    identifier={id}
    onClick={onClick}
  >
    {entry.name}
  </Entry>)

})

@dispatcher
@observer
class PMPageToolbar extends Component {

  static propTypes = {
    className: PropTypes.string,
    ui: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    page: PropTypes.object,
    project: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.permissions = hasPermissions([
      'pageSettingDialog',
      'pageSecurityDialog',
      'publishPage',
      'unpublishPage',
      'archivePage',
      'deletePage'
    ])
  }

  isValidEntry(entry) {

    if (entry === '-') {
      return true
    }

    if (!entry.name) {
      console.warn('PMPageToolbar: A custom entry HAS to have a name property of type string')
      return false
    }

    if (!('handleClick' in entry)) {
      // eslint-disable-next-line max-len
      console.warn('PMPageToolbar: A custom entry HAS to have an handleClick property of type function')
      return false
    }

    return true
  }

  @autobind
  handleEntryClick({ target }) {
    const entry = this.props.ui.pageContextTools[target.identifier]
    entry.handleClick()
    if (entry.handleQueryState) {
      entry.handleQueryState(entry.state)
    }
  }

  @autobind
  handleDeletePage() {

    this.deletePage({ archive: false })

  }

  @autobind
  handleArchivePage() {

    this.deletePage({ archive: true })

  }

  @autobind
  handleRestorePage() {
    pageStore.restorePage(this.props.page.current)
  }

  @autobind
  handleSettingsDialog() {
    const page = this.props.page.current
    const channel = this.props.project.current.channel.shortcut
    const selectedTab = 'general'

    pageSettings({
      page,
      channel,
      selectedTab
    }).then((result) => {

      const i18nKey = getI18nLangFromObject(page)

      const pageData = {
        ...result.data,
        hideNavigation: !result.data.showInNavigation,
        hideInSearch: !result.data.showInSearch,
        i18n: {
          [i18nKey]: {
            ...page.i18n[i18nKey],
            ...result.data.i18n
          }
        }
      }

      page.set(pageData)
      page.save()

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handlePublishPage() {

    const page = this.props.page.current
    publishPage({
      page,
      jobType: 'publish'
    })
      .then((result) => {
        result.publish.publishAction = 'publish'
        pageStore.publish(page, result.publish, {
          id: page.id,
          action: 'publish'
        })
      })
      .catch((err) => {
      // Catch the cancel event from the dialog
        console.log(err)
      })
  }

  @autobind
  handlePagePlanning() {
    const page = this.props.page.current
    const existingUnpublishAt = page.unpublishAt
    const existingPublishAt = page.publishAt

    pagePlanning({
      page
    })
      .then((result) => {
      // adding planning to all actions for status message

        // only run if publishAt is set and not the same as before
        if (result.publish.publishAt
        && result.publish.publishAt !== existingPublishAt
        ) {
          result.publish.publishAction = 'planning'
          pageStore.publish(page, result.publish, {
            id: page.id,
            action: 'publish'
          })
        }
        // Cancel a Publish At job
        if (!result.publish.publishAt && existingPublishAt) {
          pageStore.publish(page, { publishAction: 'planning' }, {
            id: page.id,
            action: 'publish/cancel'
          })
            .then(() => {
              this.props.page.current.publishAt = null
            })
        }
        // Check if unpublish action is required
        if (result.unpublish.unpublishAt
        && (existingUnpublishAt !== result.unpublish.unpublishAt)
        ) {
          result.unpublish.publishAction = 'planning'
          pageStore.publish(page, result.unpublish, {
            id: page.id,
            action: 'unpublish'
          })
        }
        // User removed unpublishing job and so the unpublish route is needed
        if (!result.unpublish.unpublishAt
        && (existingUnpublishAt !== result.unpublish.unpublishAt)
        ) {
          pageStore.publish(page, { publishAction: 'planning' }, {
            id: page.id,
            action: 'unpublish/cancel'
          })
            .then(() => {
              this.props.page.current.unpublishAt = null
            })
        }
      })
      .catch((err) => {
      // Catch the cancel event from the dialog
        console.log(err)
      })
  }

  @autobind
  handleInternalLinkDialog() {
    internalLink({
      headerTextId: 'page-internal-link-dialog.header-text-offline',
      closeButtonId: 'simple-dialog.cancel',
      confirmButtonId: 'simple-dialog.continue',
      pageId: this.props.page.current.id,
      router: this.props.router,
    }).then(() => {
      this.handleUnpublishPage()
    })
  }

  @autobind
  handlePageOffline() {
    // If internal links refer to the current page, show a list to the user before proceeding with unpublish
    const internalLinkCount = deepGet(this, 'props.page.current.internalLinks.data.length')
    if (!internalLinkCount) {
      return this.handleUnpublishPage()
    }

    return this.handleInternalLinkDialog()
  }

  @autobind
  handleUnpublishPage() {
    const page = this.props.page.current
    publishPage({
      page,
      jobType: 'unpublish'
    })
      .then((result) => {
        result.unpublish.publishAction = 'unpublish'
        pageStore.publish(page, result.unpublish, {
          id: page.id,
          action: 'unpublish'
        })
      })
      .catch((err) => {
      // Catch the cancel event from the dialog
        console.log(err)
      })
  }

  @autobind
  handleOpenPageSecurityDialog() {

    const page = this.props.page.current

    const filtered = filter(
      page || {},
      [
        'releasePublicationLockAt',
        'publicationNotAllowed',
        'isGhostPage'
      ]
    )
    pageSecurity({
      filtered
    }).then((result) => {

      page.set({
        ...result.data,
        meta: page.meta
      })
      page.save()

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  @autobind
  handleCenshareConnect() {
    return uiStore.openDialog('censhare', {
      page: this.props.page.current,
      project: this.props.project.current
    })
  }

  @autobind
  handleOpenCenshareRefresh() {
    const page = this.props.page.current

    censhareRefresh({
      model: page,
      censhareStore
    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  deletePage(opts) {
    const page = this.props.page.current
    const partialPage = pageStore.getPartialById(page.id)
    const pageId = page.id
    const parentId = page.parentId

    if (!partialPage.isDeletable) {
      const list = partialPage.publishedChildren.map((child) => {
        return i18n(child, 'name')
      })

      alert(
        formatMessage(
          { id: opts.archive ? 'page.alert.archive.info' : 'page.alert.delete.info' },
          {
            name: page.name
          },
        ),
        formatMessage({
          id: opts.archive
            ? 'page.alert.archive.title'
            : 'page.alert.delete.title'
        }),
        {
          list
        }
      )

      return
    }

    confirmDelete({
      page,
      opts
    }).then(() => {
      // only change url if deleting
      if (!opts || !opts.archive) {
        let pageToLoad = parentId
        if (parentId < 0) {
          const defaultPage = this.props.project.current.getDefaultPage()
          pageToLoad = defaultPage ? defaultPage.id : parentId
        }

        this.context.dispatch(
          this.props.commands.EditItemCommand,
          {
            router: this.props.router,
            type: 'page',
            id: pageToLoad
          }
        )
      }

      pageStore.actionDestroyItem(pageId, opts)

    }).catch((err) => {
      // Catch the cancel event from the dialog
      console.log(err)
    })
  }

  renderCustomContextTools() {
    const { ui: { pageContextTools } } = this.props
    return (<Entry>

      <DropdownOpener>
        <FormattedMessage id="page.settings" />
      </DropdownOpener>

      <Dropdown>

        {pageContextTools.map((entry, id) => (entry === '-'

          ? <Separator />

          : <ObservedEntry
            key={entry.name}
            entry={entry}
            onClick={this.handleEntryClick}
            id={id}
          />

        ))}

      </Dropdown>

    </Entry>)
  }

  renderArchiveView() {
    return (this.permissions.archivePage
      ? <Entry onClick={this.handleRestorePage}>
        <FormattedMessage id="page.restore" />
      </Entry>
      : null)
  }

  renderNonArchiveView(isCurrentPagePublished, isCurrentPagePublicationNotAllowed) {
    return (<div>
      {this.permissions.publishPage
        ? <Entry
          disabled={isCurrentPagePublicationNotAllowed}
          onClick={this.handlePublishPage}
        >
          <FormattedMessage id="page.release" />
        </Entry>
        : null}

      {this.permissions.publishPage
        ? <Entry
          onClick={this.handlePagePlanning}
          disabled={isCurrentPagePublicationNotAllowed}
        >
          <FormattedMessage id="page.planning" />...
        </Entry>
        : null}

      {this.permissions.unpublishPage || this.permissions.archivePage
        ? <Separator />
        : null}

      {this.permissions.unpublishPage
        ? <Entry
          onClick={this.handlePageOffline}
          disabled={!isCurrentPagePublished || isCurrentPagePublicationNotAllowed}
        >
          <FormattedMessage id="page.take-offline" />
        </Entry>
        : null}

      {this.permissions.archivePage
        ? <Entry
          onClick={this.handleArchivePage}
          disabled={isCurrentPagePublished}
        >
          <FormattedMessage id="page.archive" />...
        </Entry>
        : null}

    </div>)
  }

  renderCenshareView() {
    return [
      <Separator key="separator" />,

      <Entry
        key="censhare-connect"
        onClick={this.handleCenshareConnect}
      >
        <FormattedMessage id="page.censhare-connect" />...
      </Entry>,
      <Entry
        key="censhare-refresh"
        onClick={this.handleOpenCenshareRefresh}
      >
        <FormattedMessage id="page.censhare-refresh" />...
      </Entry>
    ]
  }

  render() {
    let { ui: { pageContextTools } } = this.props
    const { project, page, disabled } = this.props
    pageContextTools = (pageContextTools || []).filter(this.isValidEntry)

    const divProps = filterDivAttrs(this.props)

    let isCurrentPagePublished = false
    let isCurrentPagePublicationNotAllowed = false
    let isArchive = false

    // In case that the page is not fully loaded
    if (page.hasCurrent) {

      const currentPage = page.current

      // If the page is part of the archive
      isArchive = currentPage.type === PAGE_TYPE_ARCHIVE_PAGE
        || currentPage.type === PAGE_TYPE_ARCHIVE_SPECIAL_PAGE

      isCurrentPagePublished = !!currentPage.publishedUrl

      isCurrentPagePublicationNotAllowed = !!currentPage.publicationNotAllowed

    }

    return (<div {...divProps}
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer>
        <GenevaDropdownOpener
          clickToClose
          disabled={disabled || !this.permissions.length}
          caret={false}
          arrow
        >
          <FormattedMessage id="page.page" />
        </GenevaDropdownOpener>
        <Dropdown
          className={`geneva-dropdown ${testClass('page-dropdown')}`}
        >

          {pageContextTools && pageContextTools.length
            ? this.renderCustomContextTools()
            : null}

          {this.permissions.pageSettingDialog
            ? <Entry
              onClick={this.handleSettingsDialog}
              className={testClass('page-dropdown-settings')}
            >
              <FormattedMessage id="page.settings" />...
            </Entry>
            : null}

          {this.permissions.pageSecurityDialog
            ? <Entry
              onClick={this.handleOpenPageSecurityDialog}
              className={testClass('page-dropdown-premissions')}
            >
              <FormattedMessage id="page.permissions" />...
            </Entry>
            : null}

          <Separator />
          {
            isArchive
              ? this.renderArchiveView()
              : this.renderNonArchiveView(
                isCurrentPagePublished,
                isCurrentPagePublicationNotAllowed
              )
          }

          {this.permissions.deletePage
            ? <Entry
              onClick={this.handleDeletePage}
              disabled={isCurrentPagePublished}
              className={testClass('page-dropdown-delete')}
            >
              <FormattedMessage id="page.delete" />...
            </Entry>
            : null}

          {project.current.hasCenshare
            ? this.renderCenshareView()
            : null}

        </Dropdown>
      </DropdownContainer>

    </div>)

  }
}

export default commandConnector({
  EditItemCommand
})(
  withRouter(PMPageToolbar)
)
