exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun{padding-top:0}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun .channel{color:grey}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun hr{margin:5px 0}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun li.disabled-projects{color:grey;cursor:default;display:block;font-family:Arial, \"Helvetica Neue\", \"Helvetica\", Helvetica, sans-serif;padding:4px 10px;padding-left:24px;padding-right:24px;white-space:nowrap}.src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun li.disabled-projects:hover{background-color:transparent}\n", "", {"version":3,"sources":["/builds/GENEVA/wm/frontend/src/shared/components/LimitedProjectDropdown/styles.scss"],"names":[],"mappings":"AAAA,qFACE,aAAc,CADhB,8FAII,UAAW,CAJf,wFAQI,YAAa,CARjB,0GAcQ,WACA,eACA,cACA,wEACA,iBACA,kBACA,mBACA,kBAAmB,CArB3B,gHAwBU,4BAA6B,CAC9B","file":"styles.scss","sourcesContent":[".limitedProjectDropdown {\n  padding-top: 0;\n\n  :global(.channel) {\n    color: grey;\n  }\n\n  hr {\n    margin: 5px 0;\n  }\n\n  :global {\n    li {\n      &.disabled-projects {\n        color: grey;\n        cursor: default;\n        display: block;\n        font-family: Arial, \"Helvetica Neue\", \"Helvetica\", Helvetica, sans-serif;\n        padding: 4px 10px;\n        padding-left: 24px;\n        padding-right: 24px;\n        white-space: nowrap;\n\n        &:hover {\n          background-color: transparent;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"limitedProjectDropdown": "src-shared-components-LimitedProjectDropdown-styles__limitedProjectDropdown___1ciun"
};