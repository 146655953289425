import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import { autobind, debounce } from 'core-decorators'
import classNames from 'classnames'
import { FormattedMessage } from '../../translations'
import GenevaButton from '../../ui/components/GenevaButton'
import uploadDialog from '../../shared/containers/UploadDialog'
import { deepGet } from '../../shared/obj'

import Dropdown, {
  DropdownContainer,
  Entry,
  Separator
} from '../../shared/components/Dropdown'
import GenevaDropdownOpener from '../../ui/components/GenevaDropdownOpener'

import { censhareImageRefresh } from '../../censhare/containers/dialogs'


@observer
export default class ImageCMToolbar extends Component {

  static propTypes = {
    context: PropTypes.object,
    className: PropTypes.string,
    onEditImage: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
    onErrorImage: PropTypes.func.isRequired,
    onAddMetaTags: PropTypes.func.isRequired,
    getImageDetails: PropTypes.func.isRequired,
    onImportFromMediaManager: PropTypes.func,
    onImportFromBynder: PropTypes.func,
    article: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      titleText: undefined,
      altText: undefined,
      metaLock: false,
      currentImageId: null
    }
  }

  componentDidMount() {
    this.handler = autorun(() => {
      // When context becomes image, the user clicked on a image
      if (this.props.context && this.props.context.type === 'image') {
        // get and set the image data
        const currentImage = this.props.getImageDetails()
        if (currentImage) {
          // for an image, only set it once.  This is to ignore backend saves while typing
          if (this.state.titleText === undefined && this.state.altText === undefined) {
            this.setState({
              titleText: currentImage.title || '',
              altText: currentImage.alt || '',
            })
          }
          // detect if the image has changed
          else if (currentImage.id !== this.state.currentImageId) {
            this.setState({
              titleText: currentImage.title || '',
              altText: currentImage.alt || '',
              currentImageId: currentImage.id
            })
          }
        }

        this.setState({
          metaLock: !!deepGet(this.props, 'context.target.isCenshare')
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.handler) {
      this.handler()
      this.handler = null
    }
  }

  @autobind
  handleClickEditImage() {
    if (this.props.onEditImage) {
      this.props.onEditImage()
    }
  }

  @autobind
  handlePreventFocus(event) {
    event.nativEvent.preventDefault()
  }

  @autobind
  handleImageChange(imageUrlOrFiles) {
    if (this.props.onChangeImage) {
      this.props.onChangeImage(imageUrlOrFiles)
    }
  }

  @autobind
  handleRemoveImage() {
    if (this.props.onRemoveImage) {
      this.props.onRemoveImage()
        .then(() => {
          // Reset after image got deleted
          this.setState({
            titleText: '',
            altText: ''
          })
        })
    }
  }

  @autobind
  handleFromMediamanager() {
    if (this.props.onImportFromMediaManager) {
      this.props.onImportFromMediaManager()
    }
  }

  @autobind
  handleFromBynder() {
    if (this.props.onImportFromBynder) {
      this.props.onImportFromBynder()
    }
  }

  @autobind
  handleError(err) {
    if (this.props.onErrorImage) {
      this.props.onErrorImage(err)
    }
  }

  @autobind
  handleImageUpload() {

    uploadDialog({
      config: {
        multiple: false
      },
      mediaType: 'image',
      handleChange: this.handleImageChange,
      handlePreventFocus: this.handlePreventFocus,
      handleError: this.handleError
    }).then((result) => {

      if (result.type && result.type === 'fromMediamanager') {
        // Replacing a single image using MediaManager
        this.handleFromMediamanager()
      }
      else if (result.type && result.type === 'fromBynder') {
        // Replacing a single image using Bynder
        this.handleFromBynder()
      }
      else {
        // Replacing a single image using URL
        this.handleImageChange(result.url)
      }
    })
  }

  @autobind
  handleTitleInput({ target }) {
    this.setState({ titleText: target.value })
    this.debounceMetaSave('titleText', target.value)
  }

  @autobind
  handleAltInput({ target }) {
    this.setState({ altText: target.value })
    this.debounceMetaSave('altText', target.value)
  }

  @autobind
  handleClick({ target }) {
    // Set focus to input element, because other components might steal it
    if (target && target.focus) {
      target.focus()
    }
    else {
      // Print a hint for focus issues to find it easier next time
      console.warn('Can not focus input element')
    }
  }

  @autobind
  handleMouseDown(event) {
    event.stopPropagation()
  }

  @debounce(500)
  debounceMetaSave(key, value) {
    const currentImage = this.props.getImageDetails()
    const data = {
      titleText: currentImage.title,
      altText: currentImage.alt
    }

    data[key] = value

    this.props.onAddMetaTags(data)
  }

  @autobind
  handleUpdateArticle() {
    if (this.props.onUpdateArticle) {

      return censhareImageRefresh({
        updateAction: this.props.onUpdateArticle
      })
    }
    return null
  }

  @autobind
  handleDisconnect() {
    if (this.props.onDisconnect) {
      this.props.onDisconnect('image')
    }
  }

  renderCenshareLockIndicator() {
    return (<DropdownContainer className="censhare-dropdown">
      <GenevaDropdownOpener
        clickToClose
        caret={false}
        arrow
      >
        <FormattedMessage id="censhare.sidebar.contenttype.image-box" />
      </GenevaDropdownOpener>

      <Dropdown>
        <Entry
          onClick={() => this.handleUpdateArticle()}
        >
          <FormattedMessage id="censhare.article.update" />
        </Entry>

        <Entry
          onClick={() => this.handleRemoveImage()}
        >
          <FormattedMessage id="censhare.article.remove" />
        </Entry>

        <Entry
          onClick={() => this.handleDisconnect()}
        >
          <FormattedMessage id="censhare.article.disconnect" />
        </Entry>
      </Dropdown>
    </DropdownContainer>)
  }

  render() {
    const hasValue = !!(this.props.context.target
      && this.props.context.target.value)

    const mimeType = this.props.context.target && this.props.context.target.mimeType
    const editable = mimeType !== 'svg' && mimeType !== 'gif'

    // If metaLock is active, safely assume censhare
    const hasCenshare = this.state.metaLock

    return (<div
      className={classNames(this.props.className, 'toolbar', 'v-align')}
    >
      <DropdownContainer className="image-options">

        <GenevaDropdownOpener
          clickToClose
          caret={false}
          arrow
        >
          <FormattedMessage id="image.image" />
        </GenevaDropdownOpener>

        <Dropdown>

          <Entry
            onClick={this.handleClickEditImage}
            disabled={!hasValue || !editable}
          >
            <FormattedMessage id="image.edit" />...
          </Entry>

          <Entry
            onClick={this.handleImageUpload}
            disabled={hasCenshare}
          >
            <FormattedMessage id={!hasValue ? 'image.create' : 'image.replace'} />...
          </Entry>

          <Separator />

          <Entry
            onClick={this.handleRemoveImage}
            disabled={!hasValue || hasCenshare}
          >
            <FormattedMessage id="image.delete" />...
          </Entry>

        </Dropdown>

      </DropdownContainer>

      <GenevaButton className="small plain button image-action-button"
        onClick={this.handleClickEditImage}
        disabled={!hasValue || !editable}
      >
        <FormattedMessage id="image.edit" />
      </GenevaButton>

      <GenevaButton className="small plain button image-action-button"
        onClick={this.handleImageUpload}
        disabled={hasCenshare}
      >
        <FormattedMessage id={!hasValue ? 'image.create' : 'image.replace'} />
      </GenevaButton>

      <GenevaButton
        className="small plain button image-action-button"
        onClick={this.handleRemoveImage}
        disabled={!hasValue || hasCenshare}
      >
        <FormattedMessage id="image.delete" />
      </GenevaButton>

      <span className="separator vertical" />

      <FormattedMessage className="input-title" id="image.meta-tags.title" />
      <input
        id="titleText"
        type="text"
        onChange={this.handleTitleInput}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        name="titleText"
        maxlength="255"
        value={this.state.titleText}
        disabled={this.state.metaLock}
      />

      <FormattedMessage className="alt-title" id="image.meta-tags.alt" />
      <input
        id="altText"
        type="text"
        onChange={this.handleAltInput}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        name="altText"
        maxlength="255"
        value={this.state.altText}
        disabled={this.state.metaLock}
      />
      {
        this.state.metaLock
          ? this.renderCenshareLockIndicator()
          : null
      }

    </div>)
  }
}
